Ember.TEMPLATES["javascripts/connectors/user-custom-preferences/user-nationalflags-preferences"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"siteSettings\",\"nationalflag_enabled\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"user-nationalflags-preferences\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/user-custom-preferences/user-nationalflags-preferences"}});
Ember.TEMPLATES["javascripts/connectors/user-profile-primary/show-user-card"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"custom_fields\",\"nationalflag_iso\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"class\",\"nationalflag-usersummary\"],[11,\"src\",[29,[\"/plugins/discourse-nationalflags/images/nationalflags/\",[24,[\"model\",\"custom_fields\",\"nationalflag_iso\"]],\".png\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/connectors/user-profile-primary/show-user-card"}});
define("discourse/plugins/discourse-nationalflags/discourse/components/user-nationalflags-preferences", ["exports", "discourse/models/store", "ember-addons/ember-computed-decorators", "discourse/lib/ajax"], function (_exports, _store, _emberComputedDecorators, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _emberComputedDecorators.default)('model.custom_fields.nationalflag_iso'), (_obj = {
    layoutName: 'javascripts/discourse/templates/connectors/user-custom-preferences/user-nationalflags-preferences',
    flagsource: function flagsource() {
      return this.get('model.custom_fields.nationalflag_iso') == null ? 'none' : this.get('model.custom_fields.nationalflag_iso');
    }
  }, (_applyDecoratedDescriptor(_obj, "flagsource", [_dec], Object.getOwnPropertyDescriptor(_obj, "flagsource"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-nationalflags/discourse/initializers/nationalflags", ["exports", "virtual-dom", "discourse/lib/plugin-api", "discourse/lib/ajax"], function (_exports, _virtualDom, _pluginApi, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  function initializeNationalFlags(api, siteSettings) {
    var nationalflagsEnabled = siteSettings.nationalflag_enabled;

    if (!nationalflagsEnabled) {
      return;
    }

    api.decorateWidget('poster-name:after', function (dec) {
      var result = 'none';

      if (dec.attrs && dec.attrs.userCustomFields && dec.attrs.userCustomFields.nationalflag_iso) {
        result = dec.attrs.userCustomFields.nationalflag_iso; // Ember.Logger.debug(result)
      }

      if (!result || result === 'none') {
        // Ember.Logger.debug('NOT FOUND!')
        return;
      }

      return dec.h('img', {
        className: "nationalflag-post",
        attributes: {
          src: "/plugins/discourse-nationalflags/images/nationalflags/" + result + ".png"
        }
      });
    });
    api.modifyClass('route:preferences', {
      afterModel: function afterModel(model) {
        return (0, _ajax.ajax)('/natflags/flags').then(function (natflags) {
          var localised_flags = [];
          localised_flags = natflags.flags.map(function (element) {
            return {
              code: element.code,
              pic: element.pic,
              description: I18n.t("flags.description.".concat(element.code))
            };
          }).sortBy('description');
          model.set('natflaglist', localised_flags);
        });
      }
    });
  }

  var _default = {
    name: 'nationalflag',
    initialize: function initialize(container) {
      var siteSettings = container.lookup('site-settings:main');
      (0, _pluginApi.withPluginApi)('0.1', function (api) {
        return initializeNationalFlags(api, siteSettings);
      });
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/user-nationalflags-preferences"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"control-group nationalflags\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[28,\"i18n\",[\"flags.label\"],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n    \"],[1,[28,\"combo-box\",null,[[\"nameProperty\",\"valueAttribute\",\"nameChanges\",\"content\",\"none\",\"value\"],[\"description\",\"code\",true,[24,[\"model\",\"natflaglist\"]],[24,[\"none\"]],[24,[\"model\",\"custom_fields\",\"nationalflag_iso\"]]]]],false],[0,\"\\n    \"],[7,\"img\",true],[10,\"class\",\"nationalflag-usersummary\"],[11,\"src\",[29,[\"/plugins/discourse-nationalflags/images/nationalflags/\",[22,\"flagsource\"],\".png\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/user-nationalflags-preferences"}});
define("discourse/plugins/discourse-nationalflags/wizard/components/wizard-field-national-flag", ["exports", "ember-addons/ember-computed-decorators", "wizard/lib/ajax"], function (_exports, _emberComputedDecorators, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _emberComputedDecorators.on)('init'), _dec2 = (0, _emberComputedDecorators.default)('field.value'), (_obj = {
    layoutName: 'javascripts/wizard/templates/components/wizard-field-national-flag',
    natflaglist: [],
    setup: function setup() {
      var _this = this;

      (0, _ajax.ajax)({
        url: '/natflags/flags',
        type: 'GET'
      }).then(function (natflags) {
        var localised_flags = [];
        localised_flags = natflags.flags.map(function (element) {
          return {
            code: element.code,
            pic: element.pic,
            description: I18n.t("flags.description.".concat(element.code))
          };
        }).sortBy('description');

        _this.set('natflaglist', localised_flags);
      });
    },
    flagsource: function flagsource() {
      return this.get('field.value') == null ? 'none' : this.get('field.value');
    }
  }, (_applyDecoratedDescriptor(_obj, "setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "setup"), _obj), _applyDecoratedDescriptor(_obj, "flagsource", [_dec2], Object.getOwnPropertyDescriptor(_obj, "flagsource"), _obj)), _obj)));

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/wizard/templates/components/wizard-field-national-flag"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"control-group nationalflags\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"class\",\"control-label\"],[8],[1,[28,\"i18n\",[\"flags.label\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"controls\"],[8],[0,\"\\n        \"],[1,[28,\"combo-box\",null,[[\"nameProperty\",\"valueAttribute\",\"nameChanges\",\"content\",\"none\",\"value\"],[\"description\",\"code\",true,[24,[\"natflaglist\"]],[24,[\"none\"]],[24,[\"field\",\"value\"]]]]],false],[0,\"\\n        \"],[7,\"img\",true],[10,\"class\",\"nationalflag-usersummary\"],[11,\"src\",[29,[\"/plugins/discourse-nationalflags/images/nationalflags/\",[22,\"flagsource\"],\".png\"]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/wizard/templates/components/wizard-field-national-flag"}});

